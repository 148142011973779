.pastCard-container:not(:last-of-type) {
  margin-bottom: 15px;
}

.pastCard-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 12px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
  .pastCard-description-container {
    width: 100%;
    margin-bottom: 14px;
    position: relative;
    .pastCard-description {
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 16px;
    }
    .pastCard-reason {
      font-size: 12px;
      margin-bottom: 14px;
      @media screen and (max-width: 600px) {
        margin-bottom: 0;
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(-5%);
      height: 1px;
      width: 90%;
      background-color: rgb(211, 211, 211);
      @media screen and (max-width: 600px) {
        background-color: transparent;
      }
    }
  }
  .pastCard-results-container {
    display: flex;
    justify-content: space-between;
    // margin-top: 16px;
    padding: 0 10px;
    width: 100%;
    .viewLinkBtn {
      border: none;
      background-color: #fff;
      color: #0078d4;
      font-weight: 400;
    }
  }
}
//   .upcoming-card-container {
//     background-color: #fff;
//     display: flex;
//     align-items: center;
//     position: relative;
//     border: 2px solid rgba(0, 0, 0, 0.2);
//     border-radius: 6px;
//     box-sizing: border-box;
//     @media screen and (max-width: 600px) {
//       flex-direction: column;
//       align-items: center;
//       padding: 10px;
//     }
//     .upcoming-card-cancel-container {
//       position: absolute;
//       right: 18px;
//       top: 10px;
//       .upcoming-card-cancel {
//         font-size: 0.9rem;
//         color: rgb(128, 125, 125);
//       }
//     }
//     .upcoming-card-date-container {
//       width: 20%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       padding: 50px 0;
//       position: relative;
//       @media screen and (max-width: 600px) {
//         flex-direction: row;
//         align-items: center;
//         padding: 0;
//         align-self: flex-start;
//         justify-content: flex-start;
//         margin-bottom: 10px;
//       }
//       &::after {
//         content: "";
//         position: absolute;
//         right: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         height: 70%;
//         width: 2px;
//         background-color: rgb(211, 211, 211);
//         @media screen and (max-width: 600px) {
//           background-color: transparent;
//         }
//       }
//       .upcoming-week-day,
//       .upcoming-date {
//         color: rgb(128, 125, 125);
//         font-size: 1.1rem;
//       }
//       .upcoming-week-day {
//         margin-bottom: 10px;
//         @media screen and (max-width: 600px) {
//           margin: 0 10px 0 0;
//         }
//       }
//     }
//     .upcoming-card-sub-container {
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//       align-items: flex-end;
//       padding: 0 20px;
//       @media screen and (max-width: 600px) {
//         padding: 0;
//       }
//       .upcoming-card-name-container {
//         .upcoming-card-username {
//           font-weight: 600;
//           font-size: 1rem;
//           margin-bottom: 12px;
//         }
//         .upcoming-card-medicine-name {
//           font-size: 1rem;
//           font-weight: 500;
//           margin-bottom: 12px;
//         }
//         .upcoming-card-tier-name {
//           font-size: 1.1rem;
//           font-weight: 700;
//         }
//       }
//       .upcoming-card-timing-container {
//         .upcoming-card-timing {
//           font-size: 1.2rem;
//           color: rgb(128, 125, 125);
//           margin-bottom: 12px;
//         }
//         .upcoming-card-schedule-type {
//           text-align: right;
//           color: rgb(22, 19, 54);
//           font-weight: 600;
//         }
//       }
//     }
//   }
