.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .topHeader {
    background-color: $base-color;
    height: 50px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // border: 1px solid white;
    border-top: 2px solid white;
    .ms-FocusZone {
      flex-grow: 1;
    }
    overflow: hidden;
    @media screen and (max-width: 389px) {
      overflow: auto;
    }
  }
  .routes {
    flex-grow: 1;
    max-height: calc(100% - 50px);
    width: 100%;
  }

  .signOutButton {
    margin-right: 10px;
    &:hover {
      .ms-Button-flexContainer {
        .ms-Button-icon {
          // background-color: $base-hover-background-color;
          color: $base-color;
        }
      }
    }
    .ms-Button-flexContainer {
      .ms-Button-icon {
        &:hover {
          // background-color: $base-hover-background-color;
          color: $base-color;
        }
      }
    }
  }
}
