.top-header-container {
  position: sticky;
  top: 0;
  z-index: 200;
  background-color: #fff;
  .top-header-content-lg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 0;
    @media screen and (max-width: 970px) {
      display: none;
    }
    .user-profile-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 15%;
      margin-left: auto;
      @media screen and(max-width:1024px) {
        width: 20%;
      }
      .notification-container,
      .settings-container {
        .ms-Icon {
          color: #fff;
          font-size: 1.4rem;
        }
        .ms-Button {
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
      .profile-image-container {
        border-radius: 50%;
        border: 1px solid #fff;
        height: 38px;
        width: 38px;
        .user-image {
          // height: 35px;
          width: 100%;
        }
      }
    }
  }
  .top-header-content-md {
    background-color: $base-color;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1% 5%;
    @media screen and (min-width: 971px) {
      display: none;
    }
    @media screen and (max-width: 600px) {
      padding-right: 4%;
    }
    .main-menu-icon {
      height: 20px;
      width: 20px;
    }
    .sub-menu-icon {
      height: 17px;
      width: 17px;
    }
  }
}
