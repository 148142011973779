.alertMessage-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  .alertMessage {
    padding: 15px 25px;
    color: #201f1e;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    animation-name: alert;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    .alert-icon {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  }
}

@keyframes alert {
  0% {
    transform: translateX(100%);
    opacity: 0.4;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
