.anotherProviderContainer {
  padding: 10px;
  width: 100%;
  // border: 1px solid black;
  margin-top: 5px;
  border-radius: 6px;
  @media screen and (max-width: 1024px) {
    max-width: 80%;
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }

  button {
    background-color: $base-color;
    color: white;
    border: none;

    &:disabled {
      background-color: lightgray;
    }
  }

  .anotherTimeHeader {
    margin-top: 6px;
    @media screen and (max-width: 430px) {
      h4 {
        font-size: 14px;
      }
    }
  }

  .anotherProviderBody {
    margin: 10px 0 15px;
    position: relative;
    .renderLocProviderContainer {
      position: relative;

      .noSlotError {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }
    .providerLocContainer {
      .providerContainer {
        display: flex;
        .loc {
          padding: 7px 10px 5px;
          margin-bottom: 1px;
          font-weight: 500;
        }
        .ms-Button {
          border: none;
          background-color: transparent;
          color: gray;
        }
      }
      .locContainer {
        padding-left: 40px;
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
        @media screen and (max-width: 600px) {
          padding-left: 5px;
        }
      }
      .timeBtn {
        border: none;
        height: 30px;
        width: 70px;
      }
    }
  }
  .anotherTimeFooter {
    display: flex;
    justify-content: space-evenly;

    .weekButton {
      &:hover {
        background-color: $base-hover-background-color;
        color: $base-color;
      }
      &:disabled {
        background-color: lightgray;
      }
    }
  }
}
