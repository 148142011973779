.provider-card-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 12px;
  width: 70%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
  }
  .provider-card-date-container {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    position: relative;
    @media screen and (max-width: 600px) {
      flex-direction: row;
      align-items: center;
      padding: 0;
      align-self: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 70%;
      width: 2px;
      background-color: rgb(211, 211, 211);
      @media screen and (max-width: 600px) {
        background-color: transparent;
      }
    }
    .provider-week-day,
    .provider-date,
    .provider-month {
      color: rgb(128, 125, 125);
      font-size: 1rem;
    }
    .provider-week-day,
    .provider-date {
      margin-bottom: 10px;
      @media screen and (max-width: 600px) {
        margin: 0 10px 0 0;
      }
    }
  }
  .upcoming-card-sub-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px;
    @media screen and (max-width: 600px) {
      padding: 0;
    }
    .upcoming-card-name-container,
    .upcoming-card-timing-container {
      max-width: 48%;
    }
    .upcoming-card-name-container {
      .upcoming-card-username {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 12px;
      }
      .upcoming-card-medicine-name {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 12px;
      }
      .upcoming-card-tier-name {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .upcoming-card-timing-container {
      .upcoming-card-timing {
        font-size: 1.2rem;
        color: rgb(128, 125, 125);
        margin-bottom: 12px;
        text-align: right;
      }
      .upcoming-card-duration {
        text-align: right;
        font-size: 0.7rem;
        margin-bottom: 10px;
      }
      .upcoming-card-modal-buttons {
        display: flex;
        @media screen and (max-width: 400px) {
          flex-direction: column;
          align-items: flex-end;
        }
        @media screen and (min-width: 1100px) and (max-width: 1210px) {
          flex-direction: column;
          align-items: flex-end;
        }
        .upcoming-card-schedule-type,
        .upcoming-card-cancel {
          text-align: right;
          color: rgb(22, 19, 54);
          font-weight: 500;
          cursor: pointer;
          font-size: 0.9rem;
        }
        .upcoming-card-schedule-type {
          margin-right: 20px;
          @media screen and (min-width: 1100px) and (max-width: 1210px) {
            margin: 0 0 10px 0;
          }
          @media screen and (max-width: 400px) {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}
