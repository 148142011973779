.visits-main-container {
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  // @media screen and (max-width: 971px) {
  //   height: 92%;
  // }
  .card-section,
  .chat-bot-section {
    height: 100%;
    @media screen and (max-width: 1100px) {
      height: 50%;
    }
  }
  .card-section {
    display: flex;
    flex-direction: column;
    width: 41%;
    @media screen and (max-width: 1400px) {
      width: 48%;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 94%;
      background-color: #fff;
      margin-bottom: 20px;
      z-index: 1;
      position: relative;
    }
  }
  .card-section-tab {
    .card-section-tab-items {
      display: flex;
      justify-content: flex-start;
      @media screen and (max-width: 1100px) {
        justify-content: space-around;
      }
      .upcoming-tab,
      .past-tab {
        padding: 8px 20px;
        cursor: pointer;
      }
      .upcoming-tab-toggle,
      .past-tab-toggle {
        padding: 8px 20px;
        border-bottom: 2px solid $base-color;
        cursor: pointer;
      }
    }
  }
  .see-all-card-container {
    margin: 26px 0 5px;
    cursor: pointer;
    padding-right: 12px;
    .see-all-card-text {
      text-align: right;
      font-size: 0.8rem;
      color: $base-color;
    }
  }
  .card-section-tab-content {
    flex-grow: 1;
    padding: 2px 12px 2px 2px;
    overflow-y: auto;
    .noAppointmentMessage {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 10px;
      font-weight: 600;
    }
  }

  .upcoming-card-container:not(:last-of-type) {
    margin-bottom: 15px;
  }
  .upcoming-card-container {
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-sizing: border-box;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
    .upcoming-card-date-container {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 35px 0;
      position: relative;
      @media screen and (max-width: 600px) {
        flex-direction: row;
        align-items: center;
        padding: 0;
        align-self: flex-start;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 70%;
        width: 2px;
        background-color: rgb(211, 211, 211);
        @media screen and (max-width: 600px) {
          background-color: transparent;
        }
      }
      .upcoming-week-day,
      .upcoming-date,
      .upcoming-month {
        color: rgb(128, 125, 125);
        font-size: 1rem;
      }
      .upcoming-week-day,
      .upcoming-date {
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
          margin: 0 10px 0 0;
        }
      }
    }
    .upcoming-card-sub-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 20px;
      @media screen and (max-width: 600px) {
        padding: 0;
      }
      .upcoming-card-name-container,
      .upcoming-card-timing-container {
        max-width: 48%;
      }
      .upcoming-card-name-container {
        .upcoming-card-username {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 12px;
        }
        .upcoming-card-medicine-name {
          font-size: 0.9rem;
          font-weight: 500;
          margin-bottom: 12px;
        }
        .upcoming-card-tier-name {
          font-size: 1rem;
          font-weight: 600;
        }
      }
      .upcoming-card-timing-container {
        .upcoming-card-timing {
          font-size: 1.2rem;
          color: rgb(128, 125, 125);
          margin-bottom: 12px;
          text-align: right;
        }
        .upcoming-card-duration {
          text-align: right;
          font-size: 0.7rem;
          margin-bottom: 10px;
        }
        .upcoming-card-modal-buttons {
          display: flex;
          @media screen and (max-width: 400px) {
            flex-direction: column;
            align-items: flex-end;
          }
          @media screen and (min-width: 1100px) and (max-width: 1210px) {
            flex-direction: column;
            align-items: flex-end;
          }
          .upcoming-card-schedule-type,
          .upcoming-card-cancel {
            text-align: right;
            color: rgb(22, 19, 54);
            font-weight: 500;
            cursor: pointer;
            font-size: 0.9rem;
          }
          .upcoming-card-schedule-type {
            margin-right: 20px;
            @media screen and (min-width: 1100px) and (max-width: 1210px) {
              margin: 0 0 10px 0;
            }
            @media screen and (max-width: 400px) {
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
  }
  .frozen-text-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0;
    .frozen-text {
      background-color: #e9e9eb;
      color: black;
      padding: 10px;
      max-width: 80%;
      margin-right: 20px;
      position: relative;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 12px solid #e9e9eb;
        border-bottom: 7px solid transparent;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      & > p {
        color: #fff;
        font-size: 0.9rem;
      }
    }
  }
}
