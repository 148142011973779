.registration-logo-container {
  position: absolute;
  left: 20px;
  top: 20px;

  @media screen and (max-width: 400px) {
    left: 10px;
    top: 7px;
  }
  // @media screen and (max-width: 700px) and (min-width: 301px) {
  //   left: 20px;
  //   top: 5px;
  // }

  .logo-image {
    height: 60px;
    width: 130px;

    @media screen and (max-width: 300px) {
      height: 40px;
    }
    @media screen and (max-width: 700px) and (min-width: 301px) {
      height: 50px;
    }
  }
}
.registration-tab-container {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 10px;
  .registration-text-container {
    width: 80%;
    min-width: 30%;
    margin: 0 auto;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) and (min-width: 601px) {
      width: 100%;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 10px;
    }
    .registration-text {
      text-align: left;
      color: #777;
    }
  }
  .registration-tab {
    width: 485px;
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 10px;
      // border: none;
    }
    .registration-tab-item-container {
      display: flex;
      align-items: center;
    }
    .registration-tab-item1,
    .registration-tab-item2,
    .registration-tab-item-toggle1,
    .registration-tab-item-toggle2 {
      flex-grow: 1;
      padding: 12px 0;
      background-color: #f2f4f8;
      border-bottom: 2px solid #d7e2e9;
      border-radius: 2px;
      cursor: pointer;
      .registration-tab-item-text {
        text-align: center;
        color: $base-color;
      }
    }
    .registration-tab-item-toggle1,
    .registration-tab-item-toggle2 {
      border: 2px solid #d7e2e9;
      border-radius: 2px;
      border-bottom: none;
      background-color: #fff;
      box-sizing: border-box;
    }
    .registration-tab-content {
      .form-error-text {
        color: red;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 0.9rem;
      }
    }
  }
}
.ask-for-profile-active {
  justify-content: center;
}

.sign-up-container {
  border: 2px solid #d7e2e9;
  border-radius: 2px;
  padding: 20px;
  border-top: none;
  .sign-up-form {
    padding: 0 5px;
    @media screen and (max-width: 600px) {
      padding: 0;
    }
    .username-fields-container {
      .firstName-field,
      .lastName-field {
        width: 100%;
      }
    }
    .date-field-container,
    .username-fields-container,
    .password-fields-container {
      display: flex;
      .confirm-password-field,
      .date-picker-container {
        width: 100%;
      }
      .firstName-field,
      .password-field,
      .phone-number-field {
        margin-right: 10px;
        width: 100%;
      }
    }
    .username-fields-container,
    .email-field-container,
    .date-field-container {
      margin-bottom: 10px;
      @media screen and (max-width: 600px) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 2px;
      }
    }
    .verification-code-field-container {
      margin-top: 25px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }
      .verification-field {
        width: 100%;
        margin-right: 20px;
      }
      .send-again-button {
        white-space: nowrap;
        background-color: $base-color;
        padding: 0px 25px;
        color: #fff;
        border: none;
        margin-left: 10px;
      }
      .is-disabled {
        background-color: #8b93a0;
        color: rgb(207, 200, 200);
      }
    }
    .sign-up-button-container {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .sign-up-button,
      .sign-up-button-toggle {
        background-color: $base-color;
        color: #fff;
        border: none;
        width: 50%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      .sign-up-button-toggle {
        background-color: #7a879b;
        cursor: not-allowed;
      }
    }
    .medical-field-container {
      .verify-mrn {
        // margin: 20px;
        margin-top: 10px;
        margin-left: 150px;
        margin-bottom: 0px;
        background-color: $base-color;
        color: #fff;
        border: none;
        width: 30%;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin: 0px;
        }
      }
    }
  }
}

.signIn-container {
  border: 2px solid #d7e2e9;
  border-radius: 2px;
  padding: 20px;
  border-top: none;
  .signIn-form {
    width: 100%;
    padding: 0 5px;
    // .anotherUserHeader {
    //   margin: 10px;
    // }
    .email-field-container,
    .password-field-container {
      .email-field,
      .password-field {
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 4px;
        }
      }
    }
    .forgot-password-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
      .forgot-password-text {
        font-size: 0.9rem;
        color: $base-color;
        cursor: pointer;
      }
    }
    .verification-fields-container {
      display: flex;
      align-items: flex-end;
      .verification-code-field {
        width: 100%;
        margin-right: 10px;
      }
      .verification-button {
        background-color: $base-color;
        color: #fff;
        border: none;
        white-space: nowrap;
      }
      .is-disabled {
        background-color: #6f757f;
        color: rgb(207, 200, 200);
      }
    }
    .reset-pass-fields-container {
      display: flex;
      margin-top: 10px;
      .newPass-field,
      .confirmPass-field {
        width: 100%;
      }
      .newPass-field {
        margin-right: 10px;
      }
    }
    .signIn-button-container {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .signIn-button {
        background-color: $base-color;
        color: #fff;
        border: none;
        width: 50%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
    .profile-button-container {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .profile-button {
        background-color: $base-color;
        color: #fff;
        border: none;
        width: 50%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}

.signIn-form,
.sign-up-form,
.askForProfile-form {
  .ms-TextField-field {
    background-color: #f2f4f8;
    box-sizing: border-box;
    color: $base-color;
    font-weight: 600;
    &::placeholder {
      color: $base-color;
      font-weight: 600;
      @media screen and (max-width: 600px) {
        font-size: 0.7rem;
      }
    }
    & + .ms-Button--icon {
      background-color: #f2f4f8;
      color: $base-color;
      font-weight: 600;
    }
  }
  .ms-TextField-fieldGroup {
    border-color: #b0c1cc;
    overflow: hidden;
  }
  .date-picker-container {
    .ms-TextField-field {
      color: $base-color;
      font-weight: 600;
      @media screen and (max-width: 600px) {
        font-size: 0.7rem;
      }
      & > span {
        color: $base-color;
        font-weight: 600;
        @media screen and (max-width: 600px) {
          font-size: 0.7rem;
        }
      }
      & + .msDatePickerDisabled {
        color: $base-color;
      }
    }
  }
}

.askForProfile-container {
  // border: 2px solid #d7e2e9;
  // border-radius: 2px;
  padding: 20px;
  .askForProfile-form {
    width: 100%;
    padding: 0 5px;
    .anotherUserHeader {
      font-weight: 500;
      // margin: 10px;
      text-align: center;
      color: $base-color;
    }
    .form-error-text {
      color: red;
      text-align: center;
      padding-top: 10px;
      // margin-bottom: 10px;
      font-size: 0.9rem;
    }
    .askforProfile-header {
      .askforProfile-headerText {
        font-weight: 500;
        font-size: 1.2rem;
        text-align: center;
        color: $base-color;
      }
    }

    .email-field-container,
    .password-field-container {
      .email-field,
      .password-field {
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 4px;
        }
      }
    }
    .profile-button-container {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
      }
      .profile-search-button {
        background-color: $base-color;
        color: #fff;
        border: none;
      }
      .profile-button {
        background-color: $base-color;
        color: #fff;
        border: none;
        width: 40%;
        @media screen and (max-width: 600px) {
          width: 80%;
        }
      }
    }
    .profile-search-button-container {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .profile-search-button {
        background-color: $base-color;
        color: #fff;
        border: none;
      }
    }
  }
}
