.userMessageFrozen {
  background: $base-color;
  min-width: 50%;
  max-width: 80%;
  margin: 5px;
  margin-left: auto;
  color: black;
  font-weight: 600;
  clear: both;
  border: 1px solid darkgray;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  border-radius: 8px;
  padding: 8px 0 8px 8px;
}
.chat-image {
  height: 40px;
  width: 40px;
  background-color: #e9e9eb;
  margin-right: 15px;
  border-radius: 50%;
  .chat-image-initials {
    // font-family: Arial, Helvetica, sans-serif;
    padding-top: 5px;
    font-size: 24px;
    color: black;
    text-align: center;
    margin: 0 auto;
    font-weight: 500;
  }
}
