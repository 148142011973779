.botResponseBoxContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  .botResponseBox {
    clear: both;
    border-radius: 8px;
    width: 88%;
    flex-grow: 1;

    .chat_details {
      width: 100%;
      .drug_description {
        width: 100%;
        word-break: break-word;
      }
    }

    .chat_message {
      margin-left: auto;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 0 10px 10px;
      margin: 0 0 0 auto;
      width: 100%;
    }
  }
  .chat-robot-image {
    height: 36px;
    border: 1px solid black;
    margin-right: 5px;
    border-radius: 50%;
    max-width: 36px;

    @media screen and (max-width: 700px) {
      width: 12%;
    }
  }
}
