.bookAppointment-main-container {
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  .chat-bot-section {
    height: 100%;
    @media screen and (max-width: 1100px) {
      height: 50%;
    }
  }

  .chat-bot-section {
    display: flex;
    flex-direction: column;
    width: 58%;
    @media screen and (max-width: 1400px) {
      width: 48%;
    }
    @media screen and (max-width: 1100px) {
      width: auto;
      height: auto;
      position: absolute;
      left: 15px;
      right: 15px;
      top: 15px;
      bottom: 9px;
      flex-direction: column-reverse;
      z-index: 0;
    }
  }
  .chat-bot-container {
    background-color: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
    padding-top: 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (max-width: 700px) {
      padding: 0;
    }
    @media screen and (max-width: 1100px) {
      margin-top: 0;
    }
    .chat-bot-header {
      width: 100%;
      .chat-bot-header-text {
        color: red;
        background-color: yellow;
        padding: 2px;
        font-weight: 700;
      }
    }
    .chat-bot-body {
      height: calc(100% - 10%);
      width: 100%;
      border: 2px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 5px;
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      flex-grow: 1;
      @media screen and (max-width: 700px) {
        padding: 5px;
      }
      .chat-bot-content-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        width: 100%;
        // padding-right: 15px;
        @media screen and (max-width: 700px) {
          padding: 0px;
        }
        .default-chat-bot-section {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          .chat-robot-image {
            height: 36px;
            // width: 11%;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 50%;
            max-width: 36px;

            @media screen and (max-width: 700px) {
              width: 12%;
              max-width: 36px;
            }
          }
          .default-chat-bot-buttons-container {
            width: 88%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .default-chat-bot-buttons-message {
              margin-bottom: 5px;
            }
            .default-chat-bot-buttons-message2 {
              font-weight: 500;
              margin-bottom: 5px;
            }
            .default-chat-bot-buttons {
              // background-color: #f2f4f8;
              border: none;
              text-align: left;
              // max-width: 150px;
              display: flex;
              flex-wrap: wrap;
              &:not(:last-of-type) {
                margin-bottom: 10px;
              }
              @media screen and (max-width: 700px) {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
