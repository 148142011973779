.appointmentBot {
  width: 100%;
  display: flex;
  flex-direction: column;

  // @media screen and (max-width: 768px) {
  //   width: 100%;
  // }

  .frozen-text-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0;
    .frozen-text {
      background-color: #e9e9eb;
      color: black;
      padding: 10px;
      max-width: 80%;
      margin-right: 20px;
      position: relative;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 12px solid #e9e9eb;
        border-bottom: 7px solid transparent;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      & > p {
        color: #fff;
        font-size: 0.9rem;
      }
    }
  }
}

div.appointmentBot .botResponseloading:after {
  content: " .";
  color: navy;
  // float: left;
  font-size: 200%;
  animation: dots 0.3s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }

  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(255, 255, 255, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
