@import "./variable";
@import "../components/image-uploader/ImageUploader";
@import "../components/top-header/TopHeader";
@import "../components/drawer-list/DrawerList";
@import "../components/search/Search";
@import "../components/pastAppointments/pastCard/PastCard";
@import "../components/dataLoader/DataLoader";
@import "../components/alertMessage/AlertMessage";
@import "../components/modals/reschedule-appointment/RescheduleAppointment";
@import "../components/modals/reschedule-appointment/TimeSlot";
@import "../components/chatMessages/ChatMessage";
@import "../components/conversation/Conversation";
@import "../components/chatMessages/appointmentForms/AppointmentForm";
@import "../components/chatMessages/book-cancel-appointment/anotherTime/AnotherTime";
@import "../components/chatMessages/userResponse/UserResponse";
@import "../components/chatMessages/botResponse/BotResponse";
@import "../components/chatMessages/book-cancel-appointment/deleteAppointment/DeleteAppointment";
@import "../pages/registration/Registration";
@import "../pages/error/internalServer/InternalServer";
@import "../components/textFieldToolTipLabel/TextFieldToolTipLabel";
@import "../pages/bookAppointment/BookAppointment";
@import "../pages/yourAppointments/YourAppointments";
@import "../pages/home/Home";
@import "../components/navBar/NavBar";
@import "../components/chatMessages/findAlertnativeProviderWithLoc/ProviderCard";
@import "../components/chatMessages/findAlertnativeProviderWithLoc/FindAlertnativeProviderWithLoc";
@import "../components/modals/askForVisitReason/AskForVisitReasonModal";
@import "../components/chatMessages/askTochooseService/AskToChooseService";
*,
:root,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Centra No2", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

html {
  scroll-behavior: smooth;
}

.App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  // height: calc(100vh - calc(100vh - 100%));
  // width: calc(100vw - calc(100vw - 100%));
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .modalHeading {
    font-weight: normal;

    @media screen and (max-width: 320px) {
      font-size: 1.5rem;
    }
  }

  .modalButton {
    background-color: #6dba6d;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 8px;
    border: none;
    padding: 20px;

    &:hover {
      color: #fff;
      background-color: #64a964;
    }

    @media screen and (max-width: 320px) {
      padding: 8px;
      font-size: 1rem;
    }
  }
}

.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  .modalContent {
    .header-98 {
      border-top: 4px solid $base-color;
    }
  }

  .modalActionButtonsContainer {
    margin-top: 20px;

    .modalSaveButton {
      background-color: $base-color;
      color: #fff;
      font-size: 1.2rem;
      border: none;
      margin: 0 auto;
      display: block;
      &:hover {
        color: #fff;
        background-color: $base-hover-background-color;
      }
    }

    .modalCancelButton {
      background-color: transparent;
      color: #201f1e;
      font-size: 1.2rem;

      &:hover {
        color: #201f1e;
        background-color: #e1e0e0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    font-size: 16px;
  }
}
