.navBar {
  display: flex;
  height: 100%;
  width: 100%;
  padding-right: 0px;
  padding-left: 10px;
  .ms-Nav-link {
    // background-color: transparent;
    border: 1px solid transparent;
    border-radius: 30px;
    background-color: #8681b8;
  }
  .is-selected .ms-Nav-linkText {
    color: #5d569b;
    font-weight: 700;
    font-size: 17px;
  }
  .is-selected .ms-Nav-link {
    // background-color: transparent;
    background-color: rgb(237, 235, 233);
    &::after {
      border-left: none;
    }
  }
}
