// Accordion
.accordion-item {
  background-color: transparent;

  .accordion-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;

    .accordion-header {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

div.drug_description {
  // padding: 5px;
}

.drug_description_top {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.drug_description_top input[type="submit"] {
  margin: 10px;
}

.drug_description_button form input[type="submit"] {
  margin: 10px;
}

.formContainer form {
  display: inline-block;
}

// Ask For Appointment
.another_doctor_content_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.another_doctor_content_container_hidden {
  display: none;
}

.doctors_section {
  width: 100%;
}

.doctors_section p {
  margin: 8px 0;
}

.toggle_button,
.credit-card-form-container input[type="button"] {
  background-color: $base-color;
  padding: 10px 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  margin: 10px;

  &:hover {
    background-color: $base-hover-background-color;
    color: $base-color;
  }
}

.toggle_button_onclick {
  padding: 10px 15px;
  font-weight: 700;
  font-size: 12px;
  border: none;
  border-radius: 30px;
  margin: 10px;
  margin-left: 0px;
  cursor: pointer;
  background-color: $base-hover-background-color;
  color: $base-color;
}

.doctors_section_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.drug_description_sub {
  border: 2px solid #000;
  margin-bottom: 10px;

  .book-appointment-button,
  .book-appointment-button-onClick {
    margin: 0;
    width: 100%;
    text-align: left;
    border-radius: 0;
    font-size: 10px;
    font-weight: 600;
    padding: 13px 15px;
    background-color: transparent;
    color: #000;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #000;

    &:hover {
      background-color: $base-hover-background-color;
      color: $base-color;
    }

    &:nth-of-type(5) {
      border-bottom: none;
    }
  }

  .book-appointment-button-onClick {
    background-color: $base-hover-background-color;
    color: $base-color;
  }

  .different_reason_section {
    padding: 15px;

    .book-appointment-button {
      border: none;
      padding: 0 0 13px;

      &:hover {
        background-color: $base-hover-background-color;
        color: $base-color;
      }
    }

    .different_reason_header {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .ms-TextField {
      width: 45%;

      @media screen and (max-width: 920px) {
        width: 60%;
      }

      @media screen and (max-width: 720px) {
        width: 100%;
      }

      .ms-TextField-fieldGroup {
        border: 2px solid #000;
      }

      .ms-TextField-field {
        font-size: 18px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }

        &::placeholder {
          font-size: 18px;
          line-height: 20px;

          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

//Ask For Health Plan
.plan-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.plan-button-container input[type="Submit"] {
  margin: 10px;
  padding: 6px 14px;
  font-size: 18px;
}

// Get Treatment Cost
.cost_table_container {
  padding: 10px;
  border-radius: 10px;
}

.cost_table_container_hidden {
  display: hidden;
}

.cost_table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  background-color: #e8ebf5;
}

.cost_table td,
th {
  border: 2px solid #fff;
  padding: 2px;
}

.cost_table tr td:nth-child(1),
td:nth-child(2),
th:nth-child(1),
th:nth-child(2) {
  text-align: left;
}

.sample {
  height: 100px;
  width: 100%;
  background-color: red;
}

//Credit Card
.credit-card-table,
.credit-card-modal-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.credit-card-table,
th,
td {
  white-space: nowrap;
  border: 1px solid;
  text-align: right;
  font-weight: 600;
}

.credit-card-table th,
.credit-card-modal-table th {
  padding: 2px 6px;
}

.credit-card-modal-table-container,
.credit-card-table-container {
  overflow-y: auto;
}

.credit-card-table input,
.credit-card-modal-table input {
  padding: 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;

  @media screen and (max-width: 600px) {
    width: 100px;
  }
}

.credit-card-modal-table-caption,
.credit-card-table-caption {
  font-size: 13px;
  margin-bottom: 10px;
}

.credit-card-table caption,
.credit-card-modal-table caption {
  text-align: left;
  padding: 13px 0;
  font-weight: normal;
}

.modal_ehr_container,
.verification_row,
.verification_code_row,
.conversation_row,
.social_row {
  margin-bottom: 20px;

  .ehr_header {
    margin-bottom: 10px;
  }
}

.modal_pcp_container,
.modal_pcp2_container {
  margin-bottom: 20px;

  .pcp_header,
  .pcp2_header {
    margin-bottom: 10px;
  }

  .pcp_row,
  .pcp2_row {
    p {
      margin-bottom: 10px;
    }

    input {
      width: 40%;
      height: 30px;
      padding: 10px;
      border: 1px solid #000;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.verification_code_row {
  p {
    margin-bottom: 10px;
  }

  .verification_input {
    width: 40%;
    height: 30px;
    padding: 10px;
    border: 1px solid #000;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.credit-card-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.register-modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.register-modal-header p {
  margin: 0;
  padding: 0;
}

.register-modal-container {
  position: relative;
  padding: 20px;
}

.register-modal-container .register-modal-header input[type="button"] {
  padding: 4px 40px !important;
  font-size: 90%;
  border: 1px solid #489b3d;
  margin-left: 100px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  background-color: #56b441;
  color: #fff;
  border-radius: 5px;
  box-shadow: none;

  @media screen and (max-width: 600px) {
    margin: 20px 0 0;
  }
}

.register-modal-close-icon-container {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  padding: 8px 8px 8px 8px;
}

//checkSlotBody
.checkSlotContainer {
  padding: 10px;
  min-width: 30%;
  max-width: 65%;
  // display: inline-block;
  border: 1px solid black;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .checkSlotHeader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .checkSlotBody {
    display: flex;
    // align-items: flex-start;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .checkSlotDateContainer {
      width: 50%;
      margin-right: 20px;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .root-228 {
      align-self: center;
    }
  }

  .checkSlotFooter {
    align-self: center;
  }
}

//Single Appointmet
.singleAppointmentContainer {
  padding: 10px;

  .singleAppointmentBody {
    padding: 10px;
  }

  .defaultbutton {
    background-color: $base-color;
    padding: 10px 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    margin: 10px;

    &:hover {
      background-color: $base-hover-background-color;
      color: $base-color;
    }
  }
}

.appointmentTable {
  position: relative;
}
.mergedAppointmentTableContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .mergedColumns {
    flex-grow: 1;
    margin-left: 2rem;

    .column {
      margin-top: 00.5rem;
      display: flex;
      justify-content: space-between;

      HEAD &:last-child {
        border-bottom: 1px solid gray;
      }

      .columContent {
        text-align: right;
        flex: 1;
        white-space: nowrap;
      }
    }

    .actionsColumn {
      display: flex;
      justify-content: space-between;

      .iconButton {
        margin-top: 1rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        font-size: 0.8rem;
        background-color: $base-color;
        color: white;

        &:hover {
          cursor: pointer;
        }

        .iconDisplayName {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.mergedAppointmentTableCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #dbdbdb;
  padding-top: 10px;
  border-radius: 6px;
  position: relative;

  & > h3 {
    margin-left: 1rem;
  }

  .mergedColumns {
    .rescheduleDataColumns {
      display: flex;
      // justify-content: space-around;
      align-items: flex-start;
      padding: 0.4rem 1rem 0;

      .dateSection {
        color: #005a9e;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .scheduleMonth {
          font-weight: 600;
          font-size: 15px;
        }

        .scheduleYear {
          font-weight: 600;
          font-size: 15px;
        }

        .scheduleDate {
          font-size: 22px;
          font-weight: 900;
          align-self: center;
        }
      }
      .internalColumns {
        margin-left: 5px;
      }
    }

    .column {
      margin-bottom: 0.5rem;
      margin-left: 1rem;
      display: flex;
      .columContent {
        margin-left: 0.5rem;
      }
    }

    .actionsColumn {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .iconButton {
        border-top: 1px solid #ccc;
        display: flex;
        align-items: center;
        padding: 10px 5px 10px 1rem;
        font-weight: bold;
        font-size: 0.8rem;

        &:hover {
          cursor: pointer;
        }

        .iconDisplayName {
          margin-left: 0.5rem;
          font-size: 14px;
          color: $base-color;
        }
      }
    }
  }
}

.scheduleAppointmentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0px;
  background-color: $base-color;
  color: #fff;
  font-size: 14px;
  height: 34px;
  width: 95%;
  margin-left: 9px;
  max-width: unset !important;
  border-radius: 6px;

  &:hover {
    background-color: $base-hover-background-color;
    color: $base-color;
  }

  .scheduleAppointmentButtonIcon {
    font-size: 18px;
  }

  .scheduleAppointmentButtonText {
    margin-left: 10px;
  }

  .ms-Icon {
    color: #fff;
  }
}
