.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-input {
    display: none;
  }
  .image-box {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  .user-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
  .image-upload-icon-container {
    height: 32px;
    margin-top: 20px;
    width: 150px;
    background-color: #dfe0ec;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-upload-icon {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
    .change-image-button-text {
      font-size: 13px;
      color: #56587c;
      font-weight: 600;
    }
  }
}
