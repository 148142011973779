.internalServer {
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  // // margin: 15%;
  // overflow: hidden;

  // .errorHeader {
  //   margin: 1px;
  //   padding: 1px;
  //   font-size: 1.8rem;
  //   color: rgb(44, 140, 195);
  // }
  // .errorText {
  //   margin: 1px;
  //   margin-top: 10px;
  //   font-size: 1.2rem;
  // }
  .errorHomeButton {
    margin-top: 2%;
    width: 150px;
    background-color: transparent;
    color: #201f1e;
    font-size: 1rem;
  }
}
.internalServer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 8%;

  .errorWarning {
    font-size: 50px;
    height: 50px;
    width: 50px;
    margin: 0 25px;
    align-self: center;
    color: $base-color;
  }
  .errorHeader {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    color: $base-color;
  }
  .errorTextContainer {
    width: 60%;
    align-self: center;

    @media screen and (max-width: 750px) {
      width: 70%;
    }

    @media screen and (max-width: 650px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
    .errorText {
      margin: 1px;
      font-weight: 300;
      font-size: 20px;
      padding: 10px;
    }
  }
}
