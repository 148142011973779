.deleteAppointmentContainer{
    padding: 10px;
    min-width: 30%;
    max-width: 65%;
    // display: inline-block;
    border: 1px solid black;
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  
    .deleteAppointmentHeader{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  
    .deleteAppointmentBody {
      display: flex;
      // align-items: flex-start;
  
      @media screen and (max-width: 700px) {
        flex-direction: column;
        flex-wrap: wrap;
      }
      .root-228 {
        align-self: center;
      }
    }
  
    .deleteAppointmentFooter {
      align-self: center;
    }
  }