.book-appointment-button {
  background-color: $base-color;
  // padding: 10px 15px;
  color: #fff;
  // font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 0.9rem;
  // border-width: 1px;
  margin: 10px;
  margin-left: 0px;
  align-self: flex-start;
  min-width: 150px;
  font-family: inherit;
  font-size: 0.75rem;
  line-height: 1rem;
  min-block-size: 2rem;
  padding: 0.25rem 0.75rem;

  &:hover {
    background-color: $base-hover-background-color;
    color: $base-color;
  }
  @media screen and (max-width: 767px) {
    // font-size: 16px;
    min-width: 180px;
  }
}

.appointment-button-container {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  flex-direction: column;
}
