.search-container {
  background-color: #f2f4f8;
  display: flex;
  align-items: center;
  padding: 4px 0;
  // width: 100%;
  width: 50%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .botMic {
    color: $base-color;
    height: 30px;
    width: 30px;
    margin: 0 6px;
    cursor: pointer;
  }
  .search-input-container {
    flex-grow: 1;
    .ms-TextField-field::placeholder,
    .ms-TextField-field {
      color: $base-color;
      font-size: 0.7rem;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    .ms-TextField-fieldGroup {
      background-color: transparent;
      border: none;
      &::after {
        border: none;
      }
    }
  }
  .search-send-button {
    background-color: transparent;
    border: none;
    color: $base-color;
    min-width: unset;
    padding: 10px;

    &:hover {
      color: #5d569b;
    }
  }
}

.search-link {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 4px 0;
  width: 100%;
  .another-reason-button {
    margin-left: 55px;
  }
}

.form-error-text {
  color: red;
  margin-left: 10px;
  font-size: 0.8rem;
}
