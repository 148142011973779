.drawer-list-container {
  flex-grow: 1;
  overflow-y: auto;
  // .ms-Nav-linkText {
  //   font-weight: 700;
  // }
  .is-selected .ms-Nav-linkText {
    color: rgb(0, 120, 212);
    font-weight: 700;
  }
  // .ms-Button {
  //   background-color: transparent;
  // }
  // .ms-Nav-navItems {
  //   .ms-Nav-navItem {
  //     width: 60%;
  //     margin: 0 auto;
  //     &:nth-of-type(3) {
  //       // background-color: red;
  //     }
  //     .ms-Nav-navItem {
  //       margin: 0;
  //       width: 100%;
  //       .ms-Nav-compositeLink {
  //         .ms-Button {
  //           padding-left: 17px;
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .ms-Nav-navItem:hover {
  //     background-color: transparent;
  //   }
  // }
}
