.timeSlotContainer {
  // padding: 10px;
  // min-width: 30%;
  // max-width: 90%;
  //   border: 1px solid black;
  @media screen and (max-width: 1024px) {
    // max-width: 80%;
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }

  button {
    background-color: $base-color;
    color: white;

    &:disabled {
      background-color: lightgray;
    }
  }

  // margin: 0 0 25px;
  .dateTimeContainer {
    .dateContainer {
      display: flex;
      .date {
        padding: 7px 10px 5px;
        margin-bottom: 1px;
        font-weight: 500;
      }
      .ms-Button {
        border: none;
        background-color: transparent;
        color: gray;
      }
    }
    .timeContainer {
      padding-left: 40px;
      margin: 0;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .timeBtn {
      border: none;
      height: 30px;
      width: 70px;
    }
  }
  .timeSlotFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .ms-Button-label {
      white-space: nowrap;
    }
    .weekButton {
      height: 30px;
      width: 44%;
    }
  }
}
