.dataLoader-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000001;
  display: flex;
  align-items: center;
  justify-content: center;
}
